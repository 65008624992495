var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-order-form-design-component table-component" },
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c(
            "div",
            { staticClass: "page-breadcrumb" },
            [
              _c(
                "a-breadcrumb",
                [
                  _c(
                    "a-breadcrumb-item",
                    [
                      _c("router-link", { attrs: { to: "/" } }, [
                        _vm._v("首页")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "a-breadcrumb-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/dashboard/professional-relation" } },
                        [_vm._v("工单设置")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-breadcrumb-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/dashboard/work-order-form" } },
                        [_vm._v("表单设计")]
                      )
                    ],
                    1
                  ),
                  _c("a-breadcrumb-item", [_vm._v(_vm._s(_vm.title))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-radio-group",
            {
              staticClass: "tags",
              model: {
                value: _vm.showTag,
                callback: function($$v) {
                  _vm.showTag = $$v
                },
                expression: "showTag"
              }
            },
            [
              _c("a-radio-button", { attrs: { value: "fields" } }, [
                _vm._v(" 数据库设计 ")
              ]),
              _c("a-icon", {
                staticClass: "left-dots",
                attrs: { type: "small-dash" }
              }),
              _c("a-icon", {
                staticClass: "arrow-right-icon",
                attrs: { type: "arrow-right" }
              }),
              _c("a-icon", {
                staticClass: "right-dots",
                attrs: { type: "small-dash" }
              }),
              _c("a-radio-button", { attrs: { value: "ui" } }, [
                _vm._v(" 表单设计器 ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.showTag === "fields"
        ? _c("field-component", { attrs: { "form-id": _vm.formId } })
        : _vm._e(),
      _vm.showTag === "ui"
        ? _c("ui-component", { attrs: { "form-id": _vm.formId } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }