




























import { Component } from 'vue-property-decorator';
import WorkOrderFormService from '@/service/work-order-form';
import { BaseComponent } from '@/mixins/base-component';
import WorkOrderFormDesginFieldComponent from '@/pages/dashboard/work-order-setting/work-order-form-design-fields.vue';
import WorkOrderFormDesignUIComponent from '@/pages/dashboard/work-order-setting/work-order-form-design-ui.vue';

@Component({
    components: {
        'field-component': WorkOrderFormDesginFieldComponent,
        'ui-component': WorkOrderFormDesignUIComponent
    }
})
export default class WorkOrderFormDesginComponent extends BaseComponent {
    formId: string = undefined;

    title: string = '';

    showTag: string = 'fields';

    created() {
        this.formId = this.$route.params.id;

        WorkOrderFormService.query(null, 1, 999).then(res => {
            this.title = _.filter(res.items, item => { return item.id === this.formId; })[0].displayName;
        });
    }
}
